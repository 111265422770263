/* TYPOGRAPHY */

/* Fonts */

/* Sacramento (cursive) */
@import url("https://fonts.googleapis.com/css2?family=Sacramento&display=swap");

/* Lato (sans-serif) */
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

/* Inter (sans-serif) */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap");

/* Font Size System (rem; 1rem = 10px)
1 / 1.2 / 1.4 / 1.6 / 1.8 / 2 / 2.4 / 3 / 3.6 / 4.4 / 5.2 / 6.2 / 7.4 / 8.6 / 9.8
*/

/* CUSTOM PROPERTIES */

:root {
  --color-light: #f8f9fa;
  --color-dark: #181a1c;
  --color-sage: #a2aa99;
  --color-sage-dark: #393c37;

  --text-color-white: var(--color-light);
  --text-color-black: var(--color-dark);

  --link-color-dark: var(var(--color-sage-dark));

  --bg-color: rgb(226, 226, 226);
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  min-height: 100%;
  font-size: 62.5%; /* sets 1rem = 10px instead of default 16px (10px/16px = 62.5%) */
}

body {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 1.6rem;
  color: var(--text-color-black);
  -webkit-font-smoothing: antialiased;

  background-color: var(--bg-color);
}

button {
  cursor: pointer;
  border: none;
  outline: none;
  font-family: inherit;
}

a {
  color: inherit;
  text-decoration: none;
}

li {
  list-style-type: none;
}
